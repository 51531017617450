import {client} from "../client";

const rodizioService = {
    pegarRodizioPorAno(ano) {
        return client.get(`rodizios/pegarPorAno/${ano}`);
    },

    getRodizio(igreja_id, ano) {
        return client.get(`rodizios/getRodizio/${igreja_id}/${ano}`);
    },

    getAllFilters(campoId, igrejaId, setor) {
        const params = {
            campo_id: campoId,
            igreja_id: igrejaId,
            setor: setor,
        };

        return client.get('congregacoes/getAllFilters', { params });
    },

    getYears(igrejaId) {
        return client.get(`rodizios/getAnos/${igrejaId}`);
    }

}

export {
    rodizioService
}

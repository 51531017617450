<template>
    <div class="bg-primary p-4 pb-5">
      <div class="container d-flex justify-content-center align-items-center">
        <img src="../assets/logo-ieadern-60-60.png" width="90" height="90">
        <h1 class="text-center text-white h2 fw-bold px-2 m-0">Rodízio de Pastores de {{ filialName }} {{ this.$route.params.ano }} </h1>
      </div>
    </div>
    <div class="container mt-n5">
      <div class="form-floating mb-3">
        <input type="email" class="form-control" id="filtro" placeholder="Filtre por setor, congregação ou dirigente"
          autofocus @keyup="filtrar()" v-model="search">
        <label for="filtro" class="fw-bold text-muted">Filtre por setor, congregação ou dirigente</label>
      </div>
    </div>
    <div class="template_content">
      <div class="container">
        <div class="list-group mb-3" v-for="(rodizio, index) in rodiziosExibir" :key="index">
          <div class="list-group-item list-group-item-action active" :style="rodizio.area ? {'display': 'flex', 'justify-content': 'space-between'} : ''">
            <h2 class="fw-bold h6 m-0" v-if="rodizio.area">Área {{ rodizio.area }}</h2>
            <h2 class="fw-bold h6 m-0">Setor {{ rodizio.setor }}</h2>
          </div>
          <div class="list-group-item list-group-item-action d-flex text-left"
            v-for="(congregacao, i) in rodizio.congregacoes" :key="i">
            <img v-lazy="congregacao.foto_pastor" width="90" height="90" class="rounded-circle">
            <div class="px-2 d-flex flex-column justify-content-center">
              <h3 class="h6 fw-bold text-muted m-0">{{ congregacao.nome }}</h3>
              <p class="m-0 text-muted">{{ congregacao.pastor }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="bnt-years">
        <a
          :style="{ display: anos[anoKey - 1] == null ? 'none' : '' }"
          type="button" 
          class="btn btn-outline-primary item left"
          :href="'/' + this.$route.params.igreja_id + '/' + anos[anoKey - 1]"
        >
          {{ anos[anoKey - 1] }}
        </a>

        <a
          :style="{ display: anos[anoKey + 1] == null ? 'none' : '' }"
          type="button" 
          class="btn btn-outline-primary item right"
          :href="'/' + this.$route.params.igreja_id + '/' + anos[anoKey + 1]"
        >
          {{ anos[anoKey + 1] }}
        </a>

      </div>
      <a href="https://propozzito.com?utm_source=IEADERN&utm_medium=Logomarca&utm_campaign=Rod%C3%ADzio+2023">
        <img src="https://propozzito.com/assets/images/logo-propozzito-redesign-horizontal-219x51.png" class="p-2 d-block mx-auto">
      </a>
  </div>

</template>

<script>
// import { isInteger } from "core-js/core/number";
import { rodizioService } from "../api/rodizio";

// @ is an alias to /src
export default {
  name: 'HomeView',
  // metaInfo: {
  //   title: 'My Vue.js App'
  // },
  data() {
    return {
      rodizios: [],
      // rodiziosExibir: [],
      search: '',
      filialName: '', 
      anos: [],
      anoKey: 0,
      ultimoRodizio: '',
      primeiroRodizio: '',
    }
  },
  methods: {
    verificarRodizio() {
      if (this.rodizios.length == 0) {
        window.location.href = `/${this.$route.params.igreja_id}/${this.ultimoRodizio}`
      }

    },

    anoIndex() {
      const anoVisualizando = Number(this.$route.params.ano);

      let tamanho = 0;
      for (let i = 0; this.anos[i] !== undefined; i++) {
          tamanho++;
      }

      this.primeiroRodizio =  this.anos[0];
      this.ultimoRodizio =  this.anos[tamanho - 1];
      console.log(this.primeiroRodizio);
      for (let index = 0; index <= tamanho; index++) {
        if(this.anos[index] == anoVisualizando){
          this.anoKey = index;
        }
      }
    },
    carregarDadosPelaApi() {
      rodizioService.getRodizio(this.$route.params.igreja_id, this.$route.params.ano)
        .then(success => {
          const reorganizado = success.data.reduce((acumulador, atual) => {
            const encontrado = acumulador.find(item => item.setor === atual.setor);
            if (encontrado) {
              encontrado.congregacoes.push({
                foto_pastor: atual.foto_dirigente,
                nome: atual.congregacao,
                pastor: atual.dirigente
              });
            } else {
              acumulador.push({
                setor: atual.setor,
                area: atual.area,
                congregacoes: [
                  {
                    foto_pastor: atual.foto_dirigente,
                    nome: atual.congregacao,
                    pastor: atual.dirigente
                  }
                ]
              });
            }
            return acumulador;
          }, []);

          this.rodizios = reorganizado;
        })
        .catch(error => {
          console.error('Erro ao carregar dados:', error);
        });
    },
    getFilial(){
      rodizioService.getAllFilters(null, this.$route.params.igreja_id, null)
       .then(success => {
          this.filialName = success.data.filial_name;
          document.title = "Rodízio de Pastores de " + this.filialName + ' ' + this.$route.params.ano;
        })
    },

    getYears(){
        rodizioService.getYears(this.$route.params.igreja_id)
            .then(response => {
                this.anos = response.data
            })
            .catch(error => console.error('Erro ao consultar a API:', error));

    },


    replaceByDefault(e) {
      e.target.src = 'https://s3-propozzito.s3.amazonaws.com/membros/storage/app/public/users/default.png';
    },
  },
  computed: {
    rodiziosExibir() {
      let rodizios = [];

      this.rodizios.forEach((rodizio) => {
        rodizios.push({
          setor: rodizio.setor,
          area: rodizio.area ?? null,
          congregacoes: rodizio.congregacoes.filter(congregacao => {
            return (
              congregacao.nome.toLowerCase().includes(this.search.toLowerCase()) ||
              congregacao.pastor.toLowerCase().includes(this.search.toLowerCase()) ||
              parseInt(rodizio.setor, 10).toString().includes(parseInt(this.search, 10).toString())
            );
          })
        });
      });

      rodizios = rodizios.filter(function (rodizio) {
        return rodizio.congregacoes.length > 0;
      });

      return rodizios;
    },

  },
  created: function () {
    setTimeout(() => {
      this.carregarDadosPelaApi();
    }, 1000); 

    this.getFilial();
    this.getYears();

    setTimeout(() => {
      this.anoIndex();  // Chama o método após 1 segundo
    }, 1000); 

    setTimeout(() => {
      this.verificarRodizio()
    }, 2000); 
  }
}
</script>

<style>
  html, body {
      height: 100%;
      margin: 0;
      display: flex;
      flex-direction: column;
  }

  .container {
      flex: 1; /* Faz com que o conteúdo principal ocupe o espaço disponível */
  }

  .footer {
      text-align: center; /* Centraliza a imagem horizontalmente */
      margin-top: auto; /* Garante que o rodapé fique no final da página */
  }

  .footer a {
      max-width: 100%; /* Ajusta o tamanho da imagem se necessário */
      height: auto; /* Mantém a proporção da imagem */
  }

  .rounded-circle {
    object-fit: cover;
  }
</style>
